<template>
  <div class="language-mobile-menu-backdrop">
    <navbar-mobile-header
      @close="$emit('close')"
      theme="blue"
    />

    <div class="container language-mobile-content">
      <div 
        @click="setLanguage('fr')"  
        class="language-mobile-button"
      >
        <div class="language-mobile-button-text" :style="languageButtonStyle('fr')">Français</div>
      </div>
      <div 
        @click="setLanguage('en')"
        class="language-mobile-button"
      >
        <div class="language-mobile-button-text" :style="languageButtonStyle('en')">English</div>
      </div>
      
      <div 
        @click="setLanguage('de')"
        class="language-mobile-button"
      >
        <div class="language-mobile-button-text" :style="languageButtonStyle('de')">Deutsch</div>
      </div>
      
      <div 
        @click="setLanguage('es')"
        class="language-mobile-button"
      >
        <div class="language-mobile-button-text" :style="languageButtonStyle('es')">Español</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarMobileHeader from '../navbar/NavbarMobileHeader.vue';
export default {
  components: { NavbarMobileHeader },
  computed:{
    language () {
      return this.$i18n.locale
    }
  },
  methods: {
    languageButtonStyle(language) {
      return language == this.language ? `color: white;` : ''
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;

      // Optional: Store the locale in localStorage to persist user preference
      localStorage.setItem("locale", locale);

      this.$emit('close')
    }
  },
};
</script>

<style>
  
.language-mobile-menu-backdrop {
  background-color: #4179af;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  
}

.language-mobile-content {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.language-mobile-button {
  cursor: pointer;
  position: relative;
  margin-top: 30px;
}
.language-mobile-button-text {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #DDDDDD;
}
</style>