<template>
  <div>
    <b-modal
      v-model="isLoginModal"
      content-class="login-modal"
      hide-footer
      header-class="login-modal-header"
      body-class="login-modal-body"
    >
      <template #modal-header>
        <div 
          @click="isLoginModal = false"
          class="login-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="login-modal-header--title"
          style=""
        >
          Log in or Sign up
        </div>
  
      </template>
      <div class="mt-2 text-center">
        <img 
          :src="require('@/assets/images/pictos/login_black.svg')"
          style="width: 50px;"
        />
      </div>
      <div class="mt-2 text-center">
        <strong> Welcome to AIRspirit </strong>
      </div>

      <b-form 
        @submit.prevent="login"
        class="mt-3"
      >
        <input
          class="registration-input login-input"
          type="text"
          maxlength="255"
          placeholder="Email"
          v-model="username"
          :state="$v.username.$dirty ? !$v.username.$error : null"
        >

        <input
          class="registration-input login-input mt-2"
          type="password"
          maxlength="100"
          autocomplete="on"
          placeholder="Password"
          v-model="password"
          @input="$v.password.$touch()"
          :state="$v.password.$dirty ? !$v.username.$error : null"
        >

        <div class="text-danger"
          v-if="errorLogin">
          Please verify your email and password
        </div>
        
        <button
          class="login-button mt-3"
          :disabled="$v.$invalid"
        >
          Login
        </button>

        <div class="mt-2">
          <small
            class="mt-2"
            @click="showForgetPasswordModal"
            style="cursor: pointer;"
          >
            Forgot your password?
          </small>
        </div>
      </b-form>

      <div class="or-center-line">
        <span class="or-line"></span>
        <span class="or">or</span>
        <span class="or-line"></span>
      </div>

      <button
        class="login-button"
        @click="goToPrivatePersonRegistration"
      >
        Sign up for Myself
      </button>

      <button
        class="login-button mt-2"
        @click="goToCompanyRegistration"
      >
        Sign up to manage a Business
      </button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'LoginModalComponent',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      username: '',
      password: '',
      errorLogin: false,
    }
  },
  computed : {
    contactInputInvalids: function () {
      this.$v.$touch()
      return this.$v.$invalid
    },
    loginModalNotRedirect: {
      get () {
        return this.$store.state.loginModalNotRedirect
      },
      set () {
        this.$store.commit('set', ['loginModalNotRedirect', false ])
      } 
    },
    isLoginModal: {
      get () {
        return this.$store.state.isLoginModal
      },
      set () {
        this.$store.commit('closeLoginModal')
      }
    },
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required,
    }
  },
  methods: {
    goToCompanyRegistration: function () {
      this.$router.push('/company/registration/category')
      this.$store.commit('closeLoginModal')
    },
    goToPrivatePersonRegistration: function () {
      this.$router.push('/private-person/registration')
      this.$store.commit('closeLoginModal')
    },
    login: function () {
      this.$store.commit('loadFromAPI')
      var username = this.username.toLowerCase()
      var password = this.password
      this.errorLogin = false
      this.$store.dispatch('AUTH_REQUEST', { username, password })
      .then((result) => {
        console.log(this.loginModalNotRedirect)
        if (this.loginModalNotRedirect) {
          this.loginModalNotRedirect = false
          this.$store.commit('closeLoginModal')
          return
        }
        else {
          const groupNames = result.data.groups.map(group => group.name)
          this.$store.commit('closeLoginModal')
          if (groupNames.includes('ADMIN')) {
            this.$router.push('/admin/pr')
          }
          else {
            this.$router.push('/account')
          }
        }
      })
      .catch(() => {
        this.errorLogin = true
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
   },
   showForgetPasswordModal () {
    this.$store.commit('closeLoginModal')
    this.$store.commit('openForgetPasswordModal')
   },
  }
}
</script>

<style>
.login-modal {
  border-radius: 10px !important;
  z-index: 10000;
}
.login-modal-header {
  display: block !important;
}

.login-modal-header--close-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.login-modal-header--close-icon:hover {
  background-color: #DEDEDE;
}


.login-modal-header--close-icon > img {
  height: 10px;
  display: block;
  margin: auto;
}

.login-modal-header--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  text-align: center;
  width: 300px;
}

.login-modal-body {
  padding: 10px 30px 30px 30px !important;
}

.login-input {
  border-radius: 5px !important;
}

.login-button {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.or-center-line {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background-color: #D9D9D8;
}

.or {
  color: #D9D9D8;
  margin: 0 10px;
}
</style>