<template>
  <div 
    v-if="!hideFooter"
    id="footer" 
    style="border-top: 1px solid rgb(163, 163, 163);"
  >

    <footer-content-component />
    
    <div>
      <div class="footer-copyright text-center mt-2 pb-4">
      AIRspirit © 2025
      </div>
    </div>

  </div>
</template>

<script>
import { mobileDetectionMixins } from '@/mixins/mobileDetection'
import { userMixins } from '@/mixins/userMixins'
import FooterContentComponent from '@/components/footer/FooterContentComponent.vue'

export default {
  components: { FooterContentComponent },
  name: 'FooterComponent',
  mixins: [
    mobileDetectionMixins,
    userMixins
  ],
  data: () => ({
    footerHiddenRoutes: [
      'company-registration-category',
      'company-registration-company',
      'company-registration-confirm-email',
      'company-registration-company-hq',
      'company-registration-company-geocode',
      'company-registration-company-pictures',
      'company-registration-company-qualifications',
      'company-registration-company-aircraft',
      'company-registration-aircraft-geocode',
      'company-registration-aircraft-pictures',
      'company-registration-validation',
      'private-person-registration',
    ],
  }),
  computed: {
    routeName () {
      return this.$route.name
    },
    hideFooter () {
      return (
        this.footerHiddenRoutes.includes(this.routeName) || 
        (this.routeName && this.routeName.startsWith('admin-')) ||
        this.isMobile()
      )
    },
  },
}
</script>

<style>
#footer {
  height: 150px;
  margin-top: 75px; 
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.footer-copyright {
  color: rgb(163, 163, 163);
  font-size: 11px;
}
</style>