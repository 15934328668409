<template>
  <div>
    <b-modal
        v-model="isPrivatePersonRegisterYourBusinessModal"
        content-class="airspirit-modal"
        hide-footer
        header-class="airspirit-modal-header"
        body-class="airspirit-modal-body"
    >
    <template #modal-header>
      <div 
          @click="cancelPrivatePersonRegisterYourBusinessModal"
          class="airspirit-modal-header--close-icon"
          style="float: left"
      >
          <img 
          :src="require('@/assets/images/pictos/croix.svg')"
          />
      </div>
      <div
          class="airspirit-modal-header--title"
          style=""
      >
          Register your business
      </div>

    </template>
    <div class="mt-2 text-center">
      <p>
          You are already logged as {{userFirstName}} {{userLastName}}. Do you want to log out to Register as a Business?
      </p>
    </div>

    <button
      class="airspirit-modal--button"
      @click="cancelPrivatePersonRegisterYourBusinessModal"
      >
        Cancel
    </button>

    <button
      class="airspirit-modal--button mt-2"
      @click="logoutPrivatePersonRegisterYourBusinessModal"
      >
        Logout and sign up to manage a Business
      </button>
    </b-modal>

    <div 
      :class="textAlign === 'center' ? 'text-center' : 'text-left'"
      class="footer-row"
    >
      <div class="footer-column">
        <div class="footer-column--title">
            <strong>AIRspirit</strong>
        </div>
        <div class="footer-column--item">
            <router-link class="footer-column--link" to="/who-we-are">
            {{$t('footer.who_we_are')}}
            </router-link>
        </div>
        <div class="footer-column--item">
            <router-link class="footer-column--link" to="/about-features">
            {{$t('footer.learn_new_features')}}
            </router-link>
        </div>
        <!--<div>
            <router-link class="footer-column--link" to="/">
            Letter from founder
            </router-link>
        </div>-->
        <!--<div>
            <router-link class="footer-column--link" to="/">
            Careers
            </router-link>
        </div>
        <div>
            <router-link class="footer-column--link" to="/">
            Investors
            </router-link>
        </div>-->
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
            <strong>{{$t('footer.community')}}</strong>
        </div>
        <div class="footer-column--item">
            <a class="footer-column--link" href="mailto:info@airspirit.com" target="_blank">
            {{$t('footer.contact_us')}}
            </a>
        </div>
        <div class="footer-column--item">
            <a
            @click="$store.commit('openDonationModal'); $emit('close')"
            class="footer-column--link" 
            >
            {{$t('footer.donate_to_airspirit')}}
            </a>
        </div>
        <!--<div>
            <a
            @click="$store.commit('openNewsletterSubscriberModal')"
            class="footer-column--link" 
            >
            Sign Up to our Newsletter
            </a>
        </div>-->
        <!-- <div>
            <router-link class="footer-column--link" to="/">
            Report an issue
            </router-link>
        </div>-->
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
            <strong>{{$t('footer.do_business_with_us')}}</strong>
        </div>
        <!--<div>
            <a 
            class="footer-column--link" 
            :class="{'footer-column--link--disabled': isAuthenticated}"
            @click="!isAuthenticated && $store.commit('openLoginModal')"
            >
            Login your account
            </a>
        </div>-->
        <div class="footer-column--item">
            <a
            class="footer-column--link" 
            @click="clickOnRegisterYourBusiness"
            >
            {{$t('footer.register_your_business')}}
            </a>
        </div>
        <div class="footer-column--item">
            <a
            class="footer-column--link" 
            @click="clickOnSellOnAirspirit"
            >
            {{ $t('footer.sell_on_airspirit') }}
            </a>
        </div>
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
            <strong>{{$t('footer.support_and_ressources')}}</strong>
        </div>
        <!--<div>
            <router-link class="footer-column--link" to="/">
            Help center
            </router-link>
        </div>-->
        <div class="footer-column--item">
            <router-link class="footer-column--link" to="/privacy">
            {{$t('footer.privacy_policy')}}
            </router-link>
        </div>
        <!--<div>
            <router-link class="footer-column--link" to="/">
            Sitemap
            </router-link>
        </div>-->
        <div class="footer-column--item">
            <router-link class="footer-column--link" to="/terms">
            {{$t('footer.terms_of_service')}} 
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userMixins } from '@/mixins/userMixins'


export default {
  name: 'FooterComponent',
  props:{
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  mixins: [userMixins],
  data: () => ({
    isPrivatePersonRegisterYourBusinessModal: false,
  }),
  computed: {
    routeName () {
      return this.$route.name
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    userFirstName () {
      return this.$store.getters.getUserFirstName
    },
    userLastName () {
      return this.$store.getters.getUserLastName
    },
  },
  methods: {
    cancelPrivatePersonRegisterYourBusinessModal () {
      this.isPrivatePersonRegisterYourBusinessModal = false
      this.$router.push('/account/')
    },
    clickOnRegisterYourBusiness () {
      if (!this.isAuthenticated) {
        this.$router.push('/company/registration/category')
      } else {
        if (this.userGroupContains(['COMPANY'])) {
          this.$router.push('/account/')
        }
        else {
          this.isPrivatePersonRegisterYourBusinessModal = true
        }
      }
    },
    clickOnSellOnAirspirit () {
      if (!this.isAuthenticated) {
        this.$store.commit('openLoginModal')
        this.$emit("close")
      } else {
        this.$router.push('/user/account/ads/')
      }
    },
    logoutPrivatePersonRegisterYourBusinessModal () {
      this.isPrivatePersonRegisterYourBusinessModal = false
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/company/registration/category')
      })
    },
    
    
  },
}
</script>

<style>
.footer-row {
  margin-top: 30px;
  padding-bottom: 50px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15x;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .footer-row {
    flex-direction: row;
    padding-right: 100px;
    padding-left: 100px;
  }
}

.footer-column--title {
  font-weight: bold;
  color: rgba(37, 37, 37, 0.8);
  margin-bottom: 20px;
}

.footer-column--item {
  margin-top: 10px;
}
.footer-column--link {
  cursor: pointer;
  color: rgba(37, 37, 37, 0.5);
}

.footer-column--link--disabled {
  color: rgba(37, 37, 37, 0.3);
}



</style>