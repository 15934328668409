<template>
  <div class="navbar-mobile-menu-backdrop">

    <NavbarMobileHeader
      @close="$emit('close')"
      theme="blue"
    />

    <div class="navbar-mobile-menu">
      <div
        @click="goToPage('ads')"
        class="navbar-mobile-menu-item"
      >
        Ads
      </div>
      <div
        @click="goToPage('map')"
        class="navbar-mobile-menu-item"
      >
        Map
      </div>
      <div
        @click="goToPage('news')"
        class="navbar-mobile-menu-item"
      >
        News
      </div>

      <div 
        class="navbar-mobile-menu-item"
      >
        <img
          @click="$emit('open-language-modal')"
          class="basic-navbar-picto-img"
          src="@/assets/images/navbar/languages_white.svg" 
          alt="languages" 
        />
      </div>

      <div
        class="navbar-mobile-menu-item"
      >
        <button
          @click="$emit('open-login-modal')"
          class="navbar-mobile-button"
        >
          Log In
        </button>
      </div>

      <div
        class="navbar-mobile-menu-item"
      >
        <img 
          @click="$emit('open-footer')"
          class="basic-navbar-picto-img"
          src="@/assets/images/navbar/more.svg" 
          alt="close" 
          width="20px"
        />
      </div>

    </div>

    <div class="navbar-mobile-footer">
      Airspirit &copy; 2025
    </div>
  </div>
</template>

<script>

import NavbarMobileHeader from '@/components/navbar/NavbarMobileHeader.vue'

export default {
  components: { NavbarMobileHeader },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    goToPage (page) {
      this.$emit('go-to-page', page);
    }
  },
};
</script>

<style>

.navbar-mobile-menu-backdrop {
  background-color: #4179af;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  
}

.navbar-mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  height: 100%;
  gap: 50px;
}

.navbar-mobile-menu-item {
  font-weight: bold;;
  color: white;
  font-size: 1.3em;
}

.navbar-mobile-button {
  background-color: white;
  border: unset;
  border-radius: 50px;
  color: #4179af;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
}

.navbar-mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #4179af;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 0.7em;
}
</style>