<template>
  <div 
    class="navbar-mobile-header"
    :class="theme === 'blue' ? 'navbar-mobile-header--blue' : 'navbar-mobile-header--white'"
  >
    <div 
      class="navbar-mobile-left" 
      style=""
    >
      <img 
        v-if="theme === 'blue'"
        src="@/assets/images/navbar/x1_logo_white.svg" 
        alt="airspirit" 
        width="40px"
      />
      <img 
        v-if="theme === 'white'"
        src="@/assets/images/logo_x1jets.png" 
        alt="airspirit" 
        width="40px"
      />
      <div
        class="navbar-mobile-header--title"
        :class="theme === 'blue' ? 'navbar-mobile-header--title--blue' : 'navbar-mobile-header--title--white'"
      >
        AIRspirit
      </div>
    </div>
    <div 
      class="navbar-mobile-right"
      @click="$emit('close')"
    >
      <img 
        v-if="theme === 'blue'"
        class="navbar-mobile-picto-img"
        src="@/assets/images/navbar/close.svg" 
        alt="close" 
        width="15px"
      />
      <img 
        v-if="theme === 'white'"
        class="basic-navbar-picto-img"
        src="@/assets/images/pictos/croix.svg" 
        alt="close" 
        width="15px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarMobileHeader',
  props: {
    theme: {
      type: String,
      default: 'white'
    }
  },
}
</script>

<style>
.navbar-mobile-header {
  display: flex;
  height: 80px;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px rgba(61, 61, 61, 0.1) solid; /* #3D3D3D (5%) */
  
}

.navbar-mobile-header--blue {
  background-color: #4179af !important;
}

.navbar-mobile-header--white {
  background-color: #fff !important;
}

.navbar-mobile-left {
  flex: 1;          /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-mobile-header--title  {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10px;
}

.navbar-mobile-header--title--blue {
  color: white;
}

.navbar-mobile-header--title--white {
  color: black;
}

.navbar-mobile-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
}
</style>