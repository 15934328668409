<template>
  <div id="app">
    <div id="app-body">
      <BasicNavbar
        ref="basicNavbar"
        @toggle-language-menu="showLanguageMenu = !showLanguageMenu"
      />
      <LanguageMenu
        v-if="showLanguageMenu"
        ref="languageMenu"
        @close-language-menu="showLanguageMenu = false"
      />

      <AutoPromoModal />
      <globalErrorModalComponent />
      <ForgetPasswordModalComponent />
      <LoadingComponent />
      <LoginModalComponent />
      <NewsletterSubscriberModalComponent />
      <DonationModal />
      <router-view/>
    </div>
    
    <FooterComponent />
  </div>
</template>

<script>
import AutoPromoModal from '@/components/donation/AutoPromoModal'
import BasicNavbar from '@/components/BasicNavbar'
import DonationModal from '@/components/donation/DonationModal'
import FooterComponent from '@/components/FooterComponent'
import ForgetPasswordModalComponent from '@/components/user/ForgetPasswordModalComponent'
import globalErrorModalComponent from '@/components/globalErrorModalComponent'
import LanguageMenu from '@/components/language/LanguageMenu'
import LoadingComponent from '@/components/LoadingComponent'
import LoginModalComponent from '@/components/user/LoginModalComponent'
import NewsletterSubscriberModalComponent from '@/components/newsletter/NewsletterSubscriberModalComponent'

export default {
  name: 'App',
  components : {
    AutoPromoModal,
    BasicNavbar,
    DonationModal,
    FooterComponent,
    ForgetPasswordModalComponent,
    globalErrorModalComponent,
    LanguageMenu,
    LoadingComponent,
    LoginModalComponent,
    NewsletterSubscriberModalComponent
  },
  data: () => ({
    showLanguageMenu: false
  }),
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  }
}
</script>
<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  min-height: 100vh;
}

#app-body {
  height: 100%;
  padding-bottom: 15px;
}

@media screen and (min-width: 800px) {
  #app-body {
    padding-bottom: 0px;
  }
}

.icon-button {
  cursor: pointer;
}

.desktop-only {
  display: none !important;
}

@media (min-width: 800px) {
  .desktop-only {
    display: block !important;
  }
}

@media (min-width: 800px) {
  .mobile-only {
    display: none;
  }
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

.picture-upload {
  z-index: 0;
  box-shadow: none;
  display: inline-flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 180px;
  height: 130px;
  padding: 0.8em;
  border-radius: 6px;
  border : 1px solid #ccc;
  text-align: center;
}

.picture-upload__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0;
  aspect-ratio: 16/9;
}

.picture-img-cover {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* ################# PROFILE ################# */
.profile-category-header {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.profile-footer {
  margin-top: 20px;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .profile-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.profile-back-mobile-button {
  cursor: pointer;
  margin-top: 7px;
  float:left;
  width: 30px;
}

.profile-back-pc-button {
  cursor: pointer;
  display: none;
  width: 100px;
}

@media screen and (min-width: 800px) {
  .profile-back-mobile-button {
    display: none;
  }
  .profile-back-pc-button {
    display: block;
  }
}

.profile-back-button {
  color: black;
  cursor: pointer;
  border-bottom: 2px solid black;
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 10px;
  float: left
}
.profile-validation-button {
  background-color: black;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1.1rem;
}

.profile-validation-button:disabled {
  border-color: #D9D9D8;
  background-color: #D9D9D8;
}

.profile-delete-button {
  background-color: white;
  border: 1px solid back;
  border-radius: 5px;
  color: back;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1.1rem;
}

/* ################# REGISTRATION ################# */
.registration-step-step {
  font-size: 1.5rem;
  font-weight: bold;
}
.registration-step-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
}

.registration-subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #848585;
}

.registration-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.registration-step-image {
  display: none;
}

@media screen and (min-width: 800px) {
  .registration-step-image {
    display: block;
  }
}

.registration-footer {
  background-color: white;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  padding-bottom: 30px;
  height: 150px;
  z-index: 999;
}

@media screen and (min-width: 800px) {
  .registration-footer {
    position: fixed;
  }
}

.registration-footer-one-button {
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 30px;
  text-align: right;
}

.registration-footer-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 30px;
}

.registration-validation-button {
  background-color: black;
  border: none;
  border-radius: 5px;
  color: white;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1.5rem;
}

.registration-validation-button:disabled {
  background-color: #D9D9D8;
  color: #848585;
}

.registration-back-button {
  color: black;
  cursor: pointer;
  border-bottom: 2px solid black;
  font-weight: bold;
  font-size: 1.3rem;
}

.registration-input {
  width: 100%;
  border: 1px solid #D9D9D8;
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.registration-input:focus-visible {
  border: 1px solid #D9D9D8;
}

.registration-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D9D9D8;
  opacity: 1; /* Firefox */
}
.registration-input--select {
  background-color: white;
}

.registration-input--select:required:invalid {
  color: #D9D9D8;
}
.registration-input--select option[value=""][disabled] {
  display: none;
}
.registration-input--select option {
  color: black;
}

.input-and-select-wrapper {
  position: relative; 
  display: inline-block;
  width: 100%;
}
.registration-input--inline-select {
  position: absolute;
  right: 5px;
  top: 19%;
  background-color: white;
  border: none;
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.registration-input--inline-select-select option[value=""][disabled] {
  display: none;
}

.registration-input--inline-select option {
  color: black;
}

.is-invalid-input {
  border: 1px solid red;
}
/*################# INFORMATION #################*/
.information-title {
  font-size: 1.6rem;
  font-weight: bold;;
  text-align: center;
  margin-top: 50px;
}
.information-title > img {
  width: 70px;
}

@media screen and (min-width: 800px) {
  .information-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
}
.information-section-last-updated {
  color: #848585;
  margin-top: 50px;
  
}

@media screen and (min-width: 800px) {
  .information-section-last-updated {
    margin-top: 100px;
  }
}

.information-section-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 30px;
}


/* ################# MODAL ################# */
.airspirit-modal {
  border-radius: 10px !important;
  z-index: 999999;
}
.airspirit-modal-header {
  display: block !important;
}

.airspirit-modal-header--close-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.airspirit-modal-header--close-icon:hover {
  background-color: #DEDEDE;
}


.airspirit-modal-header--close-icon > img {
  height: 10px;
  display: block;
  margin: auto;
}

.airspirit-modal-header--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  margin-top: 2px;
  text-align: center;
}

.airspirit-modal--button {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

/* ################# CARROUSSEL VueSlickCarousel################# */
.airspirit-vue-slick-carousel--dots {
  bottom: 20px !important;
}
.airspirit-vue-slick-carousel--dots > li:not(.slick-active) > button:before {
  color: white;
}



/* ################# CARROUSSEL OVERLAY ################# */
.airspirit-carousel-overlay.is-active {
    visibility: visible;
    transition: opacity 1s;
    opacity: 1;
}

.airspirit-carousel-overlay {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.5s,visibility 0.5s 0.5s;
  opacity: 0;
  background-color: #322f4b;
  background-color: rgba(0,0,0,0.8);
}

.airspirit-carousel-overlay__close {
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 15px;
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: 99999;
}

.airspirit-carousel-overlay__close--icon {
  width: 80px;
  height: 80px;
}

.airspirit-carousel-overlay__close--text {
  color: white;
}

.airspirit-carousel-overlay__wrapper {
  align-items: center;
  display: flex;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
}

.airspirit-carousel-overlay__nav > img {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.airspirit-carousel-overlay__nav__left {
  flex: 1;
  padding-left: 70px;
}
.airspirit-carousel-overlay__nav__right {
  flex: 1;
  padding-right: 70px;
}

.airspirit-carousel-overlay__img {
  text-align: center;
  width: 100%;
}

.airspirit-carousel-overlay__img > img {
  max-width: 800px;
  height: 400px;
  width: 70%;
  -o-object-fit: cover;
  object-fit: cover;
}

</style>