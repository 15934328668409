<template>
  <div class="footer-mobile-menu-backdrop">
    <NavbarMobileHeader
      @close="$emit('close')"
      theme="white"
    />

    <footer-content-component
      @close="closeModal"
      text-align="center"
    />

    <div class="footer-mobile-copyright">
      Airspirit &copy; 2025
    </div>

  </div>
</template>

<script>
import FooterContentComponent from '@/components/footer/FooterContentComponent.vue'
import NavbarMobileHeader from '@/components/navbar/NavbarMobileHeader.vue'
export default {
  components: { FooterContentComponent, NavbarMobileHeader },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style>
  
.footer-mobile-menu-backdrop {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  
}

.footer-mobile-copyright {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 0.7em;
}
</style>