<template>
  <div>
    <nav
      v-if="!isMobile()"
    >
      <div id="basic-navbar">
        <div class="basic-navbar-left" >
          <img 
            @click="goToHome"
            class="logo-img"
            src="@/assets/images/logo_x1jets.png" 
            alt="airspirit" 
          />
        </div>
        <div class="basic-navbar-center">
          <div 
            class="nav-menu-desktop"
            style="display: flex;"
          >
            <div
              class="nav-menu-desktop-item" style="border-right: 1px solid rgb(163, 163, 163);"
            >
              <a
                @click="goToPage('ads')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'ads' }"
              >
                {{ $t("navbar.ads") }}

              </a>
            </div>
            <div 
              class="nav-menu-desktop-item" 
              style="border-right: 1px solid rgb(163, 163, 163);"
            >
              <a
                @click="goToPage('map')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'map' }"
              >
                {{ $t("navbar.map") }}
              </a>
            </div>
            <div
              class="nav-menu-desktop-item"
            >
              <a
                @click="goToPage('news')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'news' }"
              >
                {{ $t("navbar.news") }}
              </a>
            </div>
            <div 
              class="nav-menu-desktop-input" 
              style="border-left: 1px solid rgb(163, 163, 163); position: relative;"
            >
              <input
                v-model="searchText"
                class="nav-menu-desktop-input-text"
                type="text"
                placeholder="Search AIRspirit..."
                @keyup.enter="search"
              />
              <div
                @click="search"
                class="nav-menu-desktop-input-button"
              >
                <img 
                  src="@/assets/images/pictos/picto_search.svg" 
                  alt="search" 
                />
              </div>
              <div v-if="isSearchDropdownOpen" class="search-dropdown-menu" ref="searchDropdownMenu">
                <!-- Dropdown content goes here -->
                <div 
                  @click="chooseSearch('ads')"
                  class="search-dropdown-item"
                >
                  Ads
                </div>
                <div 
                  @click="chooseSearch('map')"  
                  class="search-dropdown-item"
                >
                  Map
                </div>
                <div 
                  @click="chooseSearch('news')"
                  class="search-dropdown-item"
                >
                  News
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basic-navbar-right">
          <div
            style="position: relative;"
          >
            <div 
              class="basic-navbar-right-language-div" 
              @click="toggleLanguage"
            >
              <img 
                class="basic-navbar-picto-img"
                src="@/assets/images/pictos/picto_world.png" 
                alt="languages" 
              />
            </div>
          </div>

          <div 
            class="basic-navbar-right-language-div" 
            @click="clickOnLogin"
          >
            <img 
              class="basic-navbar-picto-img"
              src="@/assets/images/pictos/picto_login.png" 
              alt="login" 
            />
          </div>
        </div>
      </div>
    </nav>
    <nav
      v-else
    >
      <div id="basic-navbar">
        <div class="basic-navbar-left" style="display: flex; align-items: center;">
          <img 
            @click="goToHome"
            class="logo-img"
            src="@/assets/images/logo_x1jets.png" 
            alt="airspirit" 
          />
          <div
            class="basic-navbar-left--title"  
          >
            AIRspirit
          </div>
        </div>

        <div 
          class="basic-navbar-right" 
          @click="navbarMobileMenuOpen = !navbarMobileMenuOpen"
          style="font-size: 1.3em; cursor: pointer;"
        >
          &#9776; <!-- Unicode for three horizontal bars -->
        </div>
      </div>

      <NavbarMobileMenu
        v-if="navbarMobileMenuOpen"
        @open-language-modal="navbarMobileMenuOpen = false; showLanguageMenuModal = true"
        @open-login-modal="navbarMobileMenuOpen = false; clickOnLogin()"
        @open-footer="navbarMobileMenuOpen = false; navbarMobileFooter = true"
        @go-to-page="goToPage"
        @close="navbarMobileMenuOpen = false"
      />

      <LanguageMobileMenu
        v-if="showLanguageMenuModal"
        @close="showLanguageMenuModal = false"
      />

      <FooterMobile
        v-if="navbarMobileFooter"
        @close="navbarMobileFooter = false"
      />
      
    </nav>
  </div>
</template>

<script>
import FooterMobile from '@/components/footer/FooterMobile.vue'
import LanguageMobileMenu from '@/components/language/LanguageMobileMenu.vue'
import NavbarMobileMenu from '@/components/navbar/NavbarMobileMenu.vue'
import { mobileDetectionMixins } from '@/mixins/mobileDetection'

export default {
  name: 'BasicNavbar',
  props: {
    displayMenu: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FooterMobile,
    LanguageMobileMenu,
    NavbarMobileMenu,
  },
  mixins: [mobileDetectionMixins],
  data: function () {
    return {
      currentLocale: "en",
      disabledChatInput: false,
      navbarMobileMenuOpen: false,
      navbarMobileFooter: false,
      showLanguageMenuModal: false,
      isSearchDropdownOpen: false
    }
  },
  computed: {
    searchText: {
        get () { 
          return this.$store.state.searchText
        },
        set (value) { 
          this.$store.commit('set', ['searchText', value])
        }
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    isLoginModal () {
      return this.$store.getters.isLoginModal
    },
    loginTextStyle () {
      if (this.isLoginModal) {
        return { color: 'black' }
      }
      else {
        return { color: '#B1B1B1' }
      }
    },
    section: function () {
      return this.$route.name
    },
  },
  watch: {
    '$route' (){
      this.navbarMobileFooter = false
      this.navbarMobileMenuOpen = false
    }
  },
  beforeUnmount () {
    this.$eventHub.$off('cleanNavbarInputText')
    this.$eventHub.$off('disableNavbarInputText')
    this.$eventHub.$off('enableNavbarInputText')
  },
  mounted () {
    // Set the initial locale from localStorage or default
    const savedLocale = localStorage.getItem("locale");
    if (savedLocale) {
      this.currentLocale = savedLocale;
      this.$i18n.locale = savedLocale;
    }

    this.$eventHub.$on('setSearch', (searchText) => {
      this.searchText = searchText
    })

    this.$eventHub.$on('disableNavbarInputText', () => {
      this.disabledChatInput = true
    })

    this.$eventHub.$on('enableNavbarInputText', () => {
      this.disabledChatInput = false
    })
  },

  methods: {
    chatHandleSubmit(e) {
      e.preventDefault();
    },
    clickOnLogin () {
      this.$store.commit('closeChat')
      if (this.isAuthenticated) {
        this.$router.push("/account")
      } else {
        this.$store.commit('openLoginModal')
      }
    },
    clickOnFilters () {
      this.$eventHub.$emit('openLanguageMenuModal')
    },
    chooseSearch (section) {
      if (section === 'news') {
        this.$router.push('/news')
      }
      else if (section === 'ads') {
        this.$router.push('/ads')
      }
      else if (section === 'map') {
        this.$router.push('/map')
      }
      this.isSearchDropdownOpen = false
    },
    goToHome () {
      window.location.href = '/'
    },
    goToPage(page) {
      this.searchText = ''
      this.navbarMobileMenuOpen = false
      let route = this.$router.resolve({path: `/${page}`})
      window.location.href = route.href
    },
    handleClickOutside(event) {
      const dropdownMenu = this.$refs.searchDropdownMenu;
      if (dropdownMenu && !dropdownMenu.contains(event.target)) {
        this.isSearchDropdownOpen = false;
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    logoColor (pathName) {
      if (this.$route.name === pathName) {
        return 'black'
      }
      else {
        return 'grey'
      }
    },
    textColor (pathName) {
      if (this.$route.name === pathName) {
        return 'black'
      }
      else {
        return '#B1B1B1'
      }
    },
    search () {
      if (this.section === 'news' || this.section === 'ads' || this.section === 'map') {
        this.$store.commit('set', ['search', this.searchText])
        this.$eventHub.$emit('search')
      }
      else {
        this.toggleDropdown()
      }
      
    },
    switchLocale(locale) {
      this.currentLocale = locale;
      this.dropdownOpen = false;

      // Assuming you are using vue-i18n
      this.$i18n.locale = locale;

      // Optional: Store the locale in localStorage to persist user preference
      localStorage.setItem("locale", locale);
    },
    toggleDropdown() {
      this.isSearchDropdownOpen = !this.isSearchDropdownOpen;
      if (this.isSearchDropdownOpen) {
        setTimeout(() => {
          document.addEventListener('click', this.handleClickOutside);
        }, 100);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    toggleLanguage() {
      if (this.isMobile()) {
        this.showLanguageMenuModal = !this.showLanguageMenuModal
      }
      else {
        this.$emit('toggle-language-menu')
      }
    }
  }
}
</script>

<style>

a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: none;
}

#basic-navbar {
  display: flex;
  height: 80px;
  background: white;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px rgba(61, 61, 61, 0.1) solid; /* #3D3D3D (5%) */
}

@media (min-width: 800px) {
  #basic-navbar {
    height: 120px;
    padding-right: 100px;
    padding-left: 100px;
  }
}

.basic-navbar-left {
  flex: 1;          /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.basic-navbar-left--title {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10px;
}

@media (min-width: 800px) {
  .basic-navbar-left--title {
    margin-left: 15px;
  }
}


.basic-navbar-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  cursor: pointer;
  height: 40px;
}

@media (min-width: 800px) {
  .logo-img {
    cursor: pointer;
    height: 55px;
  }
}

.basic-navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
}

.basic-navbar-right-language-div {
  padding: 10px;
  border-radius: 50%;
}

.basic-navbar-right-language-div:hover {
  background-color: #DEDEDE;
}
.basic-navbar-right-language-div--disabled:hover {
  background-color: transparent !important;
}

.basic-navbar-picto-img {
  cursor: pointer;
  height: 25px;
  display: block;
  margin: auto;
}

.basic-navbar-picto-img--disabled {
  cursor: auto! important;
}


.nav-menu-desktop {
  border-radius: 50px;
  border: 1px solid rgb(163, 163, 163);
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: auto;
  width: 600px;
}
.nav-menu-desktop-item {
  align-items: center;
  color: rgba(255, 255, 255, .3);
  cursor: pointer;
  display: flex;
  font-weight: bold;
  gap: 15px;
  justify-content: center;
  width: 25%;
}

.nav-menu-desktop-item--disabled {
  cursor: auto !important;
}

.nav-menu-desktop-item-link--not-active {
  color: rgb(163, 163, 163);
}

.nav-menu-desktop-input {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
}

.nav-menu-desktop-input-text {
  border: none;
  outline: none;
  width: 100%;
}
.nav-menu-desktop-input-text:focus {
  border: none;
  outline: none;
}
.nav-menu-desktop-input-text:active {
  border: none;
  outline: none;
}
.nav-menu-desktop-input-text:focus-visible {
  border: none;
  outline: none;
}

.nav-menu-desktop-input-button {
  border-radius: 50%;
  cursor: pointer;
  padding: 3px 7px 5px 7px;
}

.nav-menu-desktop-input-button > img {
  width: 20px;
  height: 20px;
}

.nav-menu-desktop-chat-input-button {
  border-radius: 50%;
  cursor: pointer;
  padding: 3px 7px 5px 7px;
}

.nav-menu-desktop-chat-input-button > img {
  width: 25px;
  height: 25px;
}


/* Language  */
.language-filter-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 8px 0;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.language-dropdown li {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.language-dropdown li:hover {
  background: #f0f0f0;
}

.language-menu-modal {
  border-radius: 10px !important;
}
.language-menu-modal-header {
  display: block !important;
}

.language-menu-modal-header--close-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.language-menu-modal-header--close-icon:hover {
  background-color: #DEDEDE;
}


.language-menu-modal-header--close-icon > img {
  height: 10px;
  display: block;
  margin: auto;
}

.language-menu-modal-header--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  text-align: center;
  width: 300px;
}

.language-menu-modal-body {
  padding: 10px 30px 30px 30px !important;
}

.search-dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 150%;
  right: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100px;
  z-index: 1000;
  padding: 5px;

}

.search-dropdown-item {
  color: rgb(163, 163, 163);
  cursor: pointer;
  text-align: center;
}

.search-dropdown-item:hover {
  color: black;
}
</style>